import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  Image,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react';
import appsImage from '../../assets/fajr-app-hero-t-lg.png';
import AppStoreLinks from '../../components/AppStoreLinks'

function LandingHero() {
  return (
    <Container maxW="6xl" position="relative" minHeight={[null, null, '100vh']} overflowY={'clip'}>

      {/*
        ########################################################
        HERO Block START
        ########################################################
        */}
      <Stack
        as={Box}
        textAlign={['center', 'center', 'left']}
        spacing={{ base: 4 }}
        pt={{ base: 20, md: 36 }}
        pb={{ base: 20 }}
        zIndex={1}
        maxWidth={['100%', '100%', '40%']}
      >
        <Heading
          fontWeight={800}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight="110%"
          zIndex={1}
        >
          Salah is better than sleep.
          {' '}
          <br />
          <Text as="span" color="teal.400" bg={useColorModeValue('teal.400', 'teal.400')} backgroundClip="text">
            Never miss Fajr again.
          </Text>
        </Heading>
        <Text color={useColorModeValue('gray.800', 'gray.300')} fontWeight={500} fontSize="xl">
          Get a call straight to your mobile phone everyday for
          {' '}
          <b>Fajr</b>
          .
          <br />
          No more worries about your alarms.
          <br />
          It's that simple!

        </Text>
        <Stack
          width="100%"
          direction={["column", "row", "row"]}
          spacing={3}
          align={['center']}
          alignSelf="center"
          justifyContent={"center"}
          position="relative"
          pt={5}
        >
          <AppStoreLinks/>

          {/*<Button*/}
          {/*  colorScheme="teal"*/}
          {/*  bg="teal.400"*/}
          {/*  rounded="full"*/}
          {/*  px={6}*/}
          {/*  onClick={() => navigate('signup')}*/}
          {/*  _hover={{*/}
          {/*    bg: 'teal.500',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Sign me up!*/}
          {/*</Button>*/}
        </Stack>
      </Stack>
      {/*
        ########################################################
        HERO Block START
        ########################################################
        */}

      {/*
        ########################################################
        SUN/MOON Block START
        ########################################################
        */}
      <Box
        position={['absolute']}
        height={['200px', '250px', '300px', '400px']}
        width={['200px', '250px', '300px', '400px']}
        display="flex"
        justifyContent="center"
        alignItems="center"
        top={['-25%', '-25%', '-20%']}
        left={['-15%', '-15%', '-15%']}
      >

        <Box
          position="absolute"
          height="90%"
          width="90%"
          borderRadius="50%"
          bg={useColorModeValue('yellow.100', 'gray.700')}
        />

        <Box
          position="absolute"
          height="75%"
          width="75%"
          borderRadius="50%"
          bg={useColorModeValue('yellow.200', 'gray.400')}
        />

        <Box
          position="absolute"
          height="50%"
          width="50%"
          borderRadius="50%"
          bg={useColorModeValue('yellow.300', 'gray.200')}
        />
        {/*
          ########################################################
          SUN/MOON Block END
          ########################################################
          */}

      </Box>

      <Box
        display={['none', 'none', 'block']}
        position={'absolute'}
        top={['0', '0', '-20%', '-36%']}
        right={0}
        paddingRight={5}
      >

        <Image src={appsImage} width={['100%', '100%', '400px', '500px']}/>

      </Box>

    </Container>
  );
}

export default LandingHero;
