import { Image, Link } from '@chakra-ui/react'
import appStoreDownloadImage
  from '../assets/Download-on-the-App-Store/US/Download_on_App_Store/Black_lockup/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import playstoreStoreDownloadImage from '../assets/google-play-badge.png'
import React from 'react'

function AppStoreLinks({width = '200px'}) {

  return (
    <>
      <Link href={"https://apps.apple.com/gb/app/fajr/id1643723107"} isExternal>
        <Image src={appStoreDownloadImage} width={width} />
      </Link>
      <Link href={"https://play.google.com/store/apps/details?id=io.fajr.androidapp"} isExternal>
        <Image src={playstoreStoreDownloadImage} width={width} />
      </Link>
    </>
  )
}

export default AppStoreLinks;