import {
  Container, Divider,
  Heading,
  Stack,
  VStack, HStack,
} from '@chakra-ui/react'
import React from 'react';
import { FajrioIcon } from '../AppContainer'
import { Helmet } from 'react-helmet'

function FeatureRequestsPage() {

  return (
    <Container py={5} maxW="container.lg" paddingTop="5vh">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fajr.io | Feature Requests</title>
      </Helmet>

      <Stack spacing={8} align={'center'}>

        <HStack spacing={4} px={4} align={'start'} justify={'start'} width={'100%'}>
          <FajrioIcon/>
          <Heading textAlign={'left'} as="h2">Feature Requests</Heading>
        </HStack>
        <Container paddingTop={'0 !important'} >
          <VStack spacing={8}>



            {/* {renderPreferenceOptions()} */}
          </VStack>
        </Container>
      </Stack>

    </Container>
  );
}

export default FeatureRequestsPage;
