import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading, Text,
  Input, Textarea, Divider, Link,
  VStack, Select,
} from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../providers/AppProvider'


const issueTypes = [
  "General",
  "Feedback",
  "Feature Request",
  "Issue",
]
function HelpPage () {
  const {
    postFeedbackHelp, showSuccessToast, showErrorToast,
  } = useContext(AppContext)

  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [issueType, setIssueType] = useState('General')
  const [details, setDetails] = useState('')

  function sendFeedbackHelp () {
    if (!email) {
      showErrorToast({ message: 'Please enter an email' })
    } else if (!details) {
      showErrorToast({ message: 'Please enter the details of your request' })
    } else {
      setIsLoading(true)
      postFeedbackHelp(email, issueType, details)
        .then((response) => showSuccessToast(
          { message: 'Message sent successfully, we\' be in touch as soon as we can' },
        ))
        .catch((e) => showErrorToast({ message: 'Failed to send message' }))
        .finally(() => setIsLoading(false))
    }
  }

  return (
    <Container flex={1} paddingTop="5vh">
      <VStack align="start">
        <Heading>Speak to Us!</Heading>
        <Text>Bugs, improvements, feedback, or general questions - let us know</Text>

        <VStack align="start" spacing={6} width={'100%'}>

          {/*<Alert*/}
          {/*  status="info"*/}
          {/*  variant="left-accent"*/}
          {/*  justify="space-around"*/}
          {/*>*/}
          {/*  <AlertIcon/>*/}
          {/*  <Flex justify="space-between" align="center" flex={1}>*/}
          {/*    <VStack align="start">*/}
          {/*      <AlertTitle fontSize="md">*/}
          {/*        We hope everything is okay...*/}
          {/*      </AlertTitle>*/}
          {/*      <AlertDescription textAlign="start" fontSize="sm">*/}
          {/*        Please note we are currently in*/}
          {/*        {' '}*/}
          {/*        <b>Beta</b>*/}
          {/*        {' '}*/}
          {/*        stage and some*/}
          {/*        things may not work.*/}
          {/*        If you have had any issues please let us know.*/}
          {/*        <br/>*/}
          {/*        If there have been any issues with payments and payment*/}
          {/*        processing don't worry,*/}
          {/*        we can settle that for you.*/}
          {/*        <br/>*/}
          {/*        Just get in touch below and we'll sort it out!*/}
          {/*      </AlertDescription>*/}
          {/*    </VStack>*/}
          {/*  </Flex>*/}
          {/*</Alert>*/}

          <VStack align="start" width="100%" pt={4}>

            <FormControl>
              <FormLabel>Contact Email</FormLabel>
              <Input type="email"
                     onChange={(event) => setEmail(event.target.value)}/>
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select type="type"
                     onChange={(event) => setIssueType(event.target.value)}>
                {issueTypes.map(i => <option value={i}>{i}</option>)}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Details</FormLabel>
              <Textarea onChange={(event) => setDetails(event.target.value)}/>
            </FormControl>

            <Button isLoading={isLoading}
                    onClick={sendFeedbackHelp}>Submit</Button>
          </VStack>
        </VStack>

        <Divider/>

        <Link
          href={'https://fajrio.tawk.help/'}
          isExternal
          colorScheme={'teal'}
        >
          Checkout our FAQ
        </Link>


      </VStack>

    </Container>
  )
}

export default HelpPage
