import {
  VStack
} from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import PricingView from '../../components/PricingView'
import LandingHadith from './LandingHadith'
import LandingHero from './LandingHero'
import LandingPartnership from './LandingPartnership'
import LandingHowItWorks from './LandingHowItWorks'
import LandingFeatures from './LandingFeatures'
import LandingPromoVideo from './LandingPromoVideo'

function LandingPage () {
  const navigate = useNavigate()
  return (
    <VStack spacing={8} flex={1} width={'100%'}>
      <LandingHero />
      <LandingHadith />
      <LandingPromoVideo />
      <LandingFeatures />
      <LandingPartnership />
      {/*<LandingHowItWorks/>*/}
      {/*<PricingView/>*/}
    </VStack>
  )
}

export default LandingPage
