import {
  Button,
  FormControl,
  FormLabel,
  Divider,
  Input,
  VStack,
  HStack, Stack, Text,
} from '@chakra-ui/react'
import { FaHome } from 'react-icons/fa'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppContext } from '../providers/AppProvider'
import ResetPasswordDrawer from './ResetPasswordDrawer'
import ReactGA from 'react-ga4'
import AppStoreLinks from './AppStoreLinks'

function LoginForm ({ showSignUp = true }) {
  const [isLoading, setIsLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')

  const [isForgetPassword, setIsForgetPassword] = useState(false)

  const {
    doSignIn,
    showErrorToast,
    showSuccessToast,
  } = useContext(AppContext)
  const navigate = useNavigate()
  let [searchParams] = useSearchParams()

  useEffect(() => {
    const params = new URLSearchParams(searchParams)
    if (params.get('forgetPass') != null) {
      setIsForgetPassword(true)
    }
  }, [searchParams])

  function signIn () {
    ReactGA.event({
      action: 'Clicked Sign In',
    })
    setIsLoading(true)
    doSignIn(phoneNumber, password).then((response) => {
      showSuccessToast({ message: 'Authenticated successfully' })
      navigate('/app')
    }).catch((e) => {
      showErrorToast({ message: e.message })
      ReactGA.event({
        action: `Sign In Error: ${e.message}`,
      })
    }).finally((_) => setIsLoading(false))
  }

  function goHome () {
    navigate('/')
  }

  function onPasswordChange (event) {
    setPassword(event.target.value)
  }

  function onUsernameChange (event) {
    setPhoneNumber(event.target.value)
  }

  return (
    <>
      <VStack spacing={4}>
        <FormControl>
          <FormLabel htmlFor="tel">Email or Phone Number</FormLabel>
          <Input
            id="tel"
            type="text"
            placeholder="idris@fajr.io"
            disabled={isLoading}
            onChange={onUsernameChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="tel">Password</FormLabel>
          <Input
            id="password"
            type="password"
            placeholder="**********"
            disabled={isLoading}
            onChange={onPasswordChange}
          />
        </FormControl>
        <HStack width={'100%'} paddingBottom={5} justify={'center'}
                paddingTop={2} spacing={4}>
          <Button width={['40%']} leftIcon={<FaHome/>} onClick={goHome}>
            Home
          </Button>
          <Button width={['60%', '60%']} colorScheme={'teal'}
                  isLoading={isLoading} onClick={signIn}>
            Sign In
          </Button>
        </HStack>
        <Divider/>
        <Button
          variant="link"
          colorScheme="blue"
          size="sm"
          onClick={() => setIsForgetPassword(true)}
        >
          Forgot password?
        </Button>

        <Stack spacing={1}>
          <Text
            fontWeight={'bold'}
            color={'gray.600'}
          >
            To sign up, download the app!
          </Text>
          <HStack>
            <AppStoreLinks width={'150px'}/>
          </HStack>

        </Stack>

      </VStack>

      <ResetPasswordDrawer isOpen={isForgetPassword}
                           onClose={() => setIsForgetPassword(false)}/>
    </>
  )
}

export default LoginForm
