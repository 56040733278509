import React from 'react';
import {
  ChakraProvider,
  theme, extendTheme,
  Box, Heading, Text, Button, ColorModeScript,
} from '@chakra-ui/react';
import { BrowserRouter, Routes } from 'react-router-dom';
import { Route } from 'react-router';
import SignInPage from './pages/SignInPage';
import AppContainer from './pages/AppContainer';
import LandingPage from './pages/landing/LandingPage';
import { AppProvider } from './providers/AppProvider';
import AccountPage from './pages/AccountPage';
import FajrSchedulePage from './pages/FajrSchedulePage';
import AuthenticatedAppContainer from './pages/AuthenticatedAppContainer';
import Subscriptions from './pages/Subscriptions';
import SubscriptionSuccess from './components/subscriptions/SubscriptionSuccess';
import SubscriptionDefault from './components/subscriptions/SubscriptionDefault';
import HelpPage from './pages/HelpPage';
import SubscriptionCancelled
  from './components/subscriptions/SubscriptionCancelled';
import OurStoryPage from './pages/ourstory/OurStoryPage';
import LandingContainer from './pages/LandingContainer';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsPage from './pages/TermsPage';
import FajrSettingsPage from './pages/FajrSettingsPage'
import ReactGA from 'react-ga4';
import config from './config';
import SignUpPage from './pages/SignUpPage'
import FeatureRequestsPage from './pages/feature-requests/FeatureRequestsPage'

const appTheme = extendTheme({
  initialColorMode: 'dark',
  useSystemColorMode: true,
});

ReactGA.initialize(config.googleAnalyticsTrackingId);

function App() {
  return (
    <BrowserRouter>
      <ColorModeScript initialColorMode={appTheme.config.initialColorMode} />
      <ChakraProvider theme={appTheme}>
        <AppProvider>
          <Routes>
            <Route path="/" element={<AppContainer />}>
              <Route path="/" element={<LandingContainer />}>
                <Route index element={<LandingPage />} />
                <Route path="our-story" element={<OurStoryPage />} />
                <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="terms" element={<TermsPage />} />
              </Route>
              <Route path="signin" element={<SignInPage />} />
              <Route path="signup" element={<SignUpPage />} />
              <Route path="feature-requests" element={<FeatureRequestsPage />} />
              <Route path="help" element={<HelpPage />} />
              <Route path="app" element={<AuthenticatedAppContainer />}>
                <Route index element={<AccountPage />} />
                <Route path="schedule" element={<FajrSchedulePage />} />
                <Route path="settings" element={<FajrSettingsPage />} />
                <Route path="subscriptions" element={<Subscriptions />}>
                  <Route path="success" element={<SubscriptionSuccess />} />
                  <Route path="cancelled" element={<SubscriptionCancelled />} />
                  <Route index element={<SubscriptionDefault />} />
                </Route>
              </Route>

              <Route
                path="*"
                element={<NotFound />}
              />
            </Route>
          </Routes>
        </AppProvider>
      </ChakraProvider>
    </BrowserRouter>
  );
}

function NotFound() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, teal.400, teal.600)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text color="gray.500" mb={6}>
        The page you're looking for does not seem to exist
      </Text>

      <Button
        colorScheme="teal"
        bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
        color="white"
        variant="solid"
      >
        Go to Home
      </Button>
    </Box>
  );
}

export default App;
