import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../providers/AppProvider'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Drawer, DrawerBody,
  DrawerCloseButton,
  DrawerContent, DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex, HStack, PinInput, PinInputField, Text,
  VStack,
  Input,
} from '@chakra-ui/react'
import ReactGA from 'react-ga4'

const VerifyPhoneAlert = () => {

  const [showVerification, setShowVerification] = useState(false)
  const [verificationCode, setVerificationCode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)


  const {
    user, fetchSendPhoneVerification, postVerifyCode,
    showSuccessToast, showErrorToast,
    fetchCurrentUser
  } = useContext(AppContext)


  useEffect(() => {
    if (showVerification) {
      sendPhoneVerification()
    }
  }, [showVerification])

  function sendPhoneVerification () {
    setIsLoading(true)
    fetchSendPhoneVerification()
      .then(response => showSuccessToast({ message: 'Verification code sent' }))
      .catch(e => showErrorToast({ message: e.message }))
      .finally(() => setIsLoading(false))
  }

  function submitVerificationCode () {
    setIsLoading(true)
    postVerifyCode(verificationCode).then(response => {
      showSuccessToast({ message: 'Verification successful' })
      hideVerificationDrawer()
      fetchCurrentUser()
    }).catch(e => showErrorToast({ message: e.message }))
      .finally(() => setIsLoading(false))

    ReactGA.event({
      action: 'Clicked Submit Verification Code'
    })
  }

  function showVerificationDrawer () {
    setShowVerification(true)

    ReactGA.event({
      action: 'Clicked Verify Phone Number'
    })
  }

  function hideVerificationDrawer () {
    setShowVerification(false)
  }

  return (
    <>
      <Alert status="info" variant="solid"
             display={user == null || user?.phoneNumberVerified
               ? 'none'
               : 'flex'}
             justify={'space-around'}
      >
        <AlertIcon/>
        <Flex justify={'space-between'} align={'center'} flex={1}>
          <VStack align={'start'}>
            <AlertDescription textAlign={'start'} fontSize={'sm'} paddingRight={3}>
              You'll need to verify your account to use this service.
            </AlertDescription>
          </VStack>
          <Button variant={'solid'}  color={'black'} size={'sm'}
                  onClick={showVerificationDrawer}>Verify</Button>
        </Flex>
      </Alert>

      <Drawer
        isOpen={showVerification}
        placement="bottom"
        onClose={() => setShowVerification(false)}
      >
        <DrawerOverlay/>
        <DrawerContent>
          <DrawerCloseButton/>
          <DrawerHeader>Enter verification code</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4}>
              <Text>
                We've sent a verification code to your
                phone <b>{user?.email ?? user?.phoneNumber}</b>
              </Text>

              <HStack>
                <PinInput otp size="lg" onComplete={setVerificationCode}>
                  <PinInputField/>
                  <PinInputField/>
                  <PinInputField/>
                  <PinInputField/>
                  <PinInputField/>
                  <PinInputField/>
                </PinInput>
              </HStack>


              <Button variant="link" colorScheme="teal" mr={3}
                      onClick={sendPhoneVerification}>
                I've not received a code yet
              </Button>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <HStack width={'100%'} justify={'center'}>
            <Button variant="outline" mr={3}
                    onClick={hideVerificationDrawer}>
              Cancel
            </Button>
            <Button colorScheme="teal" onClick={submitVerificationCode}>Verify</Button>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default VerifyPhoneAlert;
