import {
  Container, Stack,
} from '@chakra-ui/react';
import React from 'react';
import {useWindowSize} from "react-use";

function LandingPromoVideo() {
  const {width} = useWindowSize();

  const vidWidth = width > 900 ? 900 : width
  const vidHeight = vidWidth / 1.7777
  return (
    <Stack
      justify="center"
      align={['center', 'center']}
      minHeight={'400px'}
    >
      <Container
        maxW="6xl"
        position="relative"
      >
        <iframe width={vidWidth} height={vidHeight}
                src="https://www.youtube.com/embed/EryjF4LLZ7s"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen></iframe>
      </Container>
    </Stack>
  );
}

export default LandingPromoVideo;
